import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PrismCode from "../../component/TextEditor/PrismCode";
import Messages from "../Comments/CommentEditor";
import UserTags from "../../component/TextEditor/UserTags";
import CommentSection from "../Comments/CommentSection";
import debounce from 'lodash.debounce';
import "./Tickets.css";
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';
import { fetchUserData, fetchTicketById, updateViewCount } from '../../component/Utils/FirebaseOperations';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import CardContainer from "../Home/CardContainer";
import SelectedUserProfile from "../Profile/SelectedUserProfile"; 
import { useSpring,animated } from "react-spring";
import { useDrag } from "react-use-gesture";
const MemoizedUserTags = React.memo(UserTags);

const Tickets = ({ user = false }) => {
  const { id } = useParams();
  const [currentTicket, setCurrentTicket] = useState(null);
  const [showMessages, setShowMessages] = useState(false);
  const [loading, setLoading] = useState(true);
  const [commentCode, setCommentCode] = useState(null);
  const [commentLang, setCommentLang] = useState(null);
  const [commentType, setCommentType] = useState('');
  const [commentSectionKey, setCommentSectionKey] = useState(Date.now());
  const [cachedUserData, setCachedUserData] = useState({});
  const [swalTrigger, setSwalTrigger] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const profilePos = useSpring({ x: 0, y: 0 })
  const bindProfilePos = useDrag((params) => {
    profilePos.x.set(params.offset[0]);
    profilePos.y.set(params.offset[1]);
  } )


  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);

  const handleToggleMessages = () => {
    setShowMessages((prevState) => !prevState);
  };

  const handleShowProfile = (selected) => {
    if(user){
      setSelectedUser(selected);
    } else {
      MySwal.fire({
        title: 'Oops',
        text: 'Para interactuar con usuarios, debes iniciar sesión',
        icon: 'warning',
        confirmButtonText: 'Volver a inicio',
        showCancelButton: true,
        cancelButtonText: 'Cerrar'
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/");
        }
      });
    }
  };




  const handleExpandClick = debounce((comment) => {
    setCommentCode(comment.code);
    setCommentLang(comment.language);
  }, 250);


  if (swalTrigger) {
    MySwal.fire({
      title: 'Para comentar, debes iniciar sesión',
      text: 'Vuelve a inicio para registrarte',
      icon: 'warning',
      confirmButtonText: 'Volver a inicio',
      showCancelButton: true,
      cancelButtonText: 'Cerrar'
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/");
      }
    });
  }

  const handleCommentAdded = () => {
    setCommentSectionKey(Date.now());
  };

  const fetchTicket = async (id) => {
    if (!id) {
      return;
    }

    setLoading(true);

    try {
      const ticketData = await fetchTicketById(id);
      if (ticketData) {
        let userData = null;
        try {
          userData = await fetchUserData(ticketData.uid, cachedUserData, setCachedUserData);
        } catch (userError) {
          console.warn("Failed to fetch user data, possibly due to permissions:", userError);
        }
        setCurrentTicket({ ...ticketData, userData });
      }
    } catch (error) {
      console.error("Failed to fetch ticket data:", error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (currentTicket && currentTicket.id) {
  //     updateViewCount(currentTicket.id)
  //       .then(() => {
  //         console.log('View count updated successfully.');
  //       })
  //       .catch(error => {
  //         console.error('Failed to update view count:', error);
  //       });
  //   }
  // }, [currentTicket]);

  useEffect(() => {
    fetchTicket(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="post-background alltickets">
      <CardContainer>
        {loading ? (
          <section className="talign-center">
            <div className="spinner icon-spinner-3" aria-hidden="true"></div>
          </section>
        ) : currentTicket ? (
          <div className="row ticket-post main-ticket-post">
            <div className="col-md-7 col-12 content-body">
              <div className="content post-box">
                <div className="meta">
                  <h2>{currentTicket.title || "sin título"}
                    <div className="fecha">
                      {formatDistanceToNow(new Date(currentTicket.createdAt?.toDate()), { addSuffix: true, locale: es })}
                    </div>
                  </h2>
                  <hr />
                  <div className="post-text">
                    {currentTicket.text ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: currentTicket.text,
                        }}
                      />
                    ) : ("...")}
                    <div className="userdata" onClick={() => { handleShowProfile(currentTicket.userData); }}>
                      <span className="username" >
                        {currentTicket.userData?.username || "Desconocido"}
                      </span>
                      <span className="usertitle">
                        {currentTicket.userData?.profession || "Desconocido"}
                      </span>
                    </div>
                  </div>
                  <hr />
                  <MemoizedUserTags tags={currentTicket.tags || []} />
                </div>
              </div>
            </div>
            <div className="col-md-5 col-12 sidebar main-code" style={{ padding: '0' }}>
              <PrismCode
                code={currentTicket.code || ""}
                language={currentTicket.language || "javascript"}
              />
            </div>
            <div className="reply-container">
              {user ? (
                <button
                  className="reply-btn "
                  onClick={() => { handleToggleMessages(); setCommentType('main') }}
                  style={{ bottom: '-.3vw' }}
                >
                  {user &&
                    <span>Comentar</span>
                  }
                </button>
              ) : (
                <button
                  className="reply-btn"
                  onClick={() => { setSwalTrigger(true) }}
                  style={{ bottom: '-.3vw' }}
                >
                  <span>Comentar</span>
                </button>
              )}
            </div>
            {showMessages && (
              <Messages
                codeContent={`// ${currentTicket.language}\n${currentTicket.code}`}
                codeLanguage={currentTicket.language}
                ticketId={id}
                commentType={commentType}
                onCommentAdded={handleCommentAdded}
              />
            )}
          </div>
        ) : (
          <Messages
            codeContent={`// ${currentTicket.language}\n${currentTicket.code}`}
            codeLanguage={currentTicket.language}
            ticketId={id}
            commentType={commentType}
            onCommentAdded={handleCommentAdded}
          />
        )}
        <div className="container row ticket-post">
          <div className="col-md-7 col-12 comm-section">
            <CommentSection
              key={commentSectionKey}
              ticketId={id}
              onExpandClick={handleExpandClick}
              onToggleMessages={handleToggleMessages} />
          </div>
          <div className=" col-md-5 col-12 sidebar">
            <PrismCode
              code={commentCode || `Presiona en "ver código <>" para visualizar`}
              language={commentLang || "javascript"}
            />
          </div>
        </div>
      </CardContainer>

      {selectedUser && selectedUser.uid &&(
        <animated.div {...bindProfilePos()} style={{
          x:profilePos.x,
          y: profilePos.y
        }} className={'aersiexplota'} >
          <SelectedUserProfile
            onClose={() => setSelectedUser(null)}
            selectedUser={selectedUser}
          />
        </animated.div>
      )}
    </div>
  );
};

export default Tickets;
