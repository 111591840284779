import React from "react";

const CardContainer = ({ children}) => {

return (
    <>
                <div className="grid">

                <div className="card">

{children}
                    <div className="shine"></div>
                    <div className="background">
                        <div className="tiles">
                            <div className="tile tile-1"></div>
                            <div className="tile tile-2"></div>
                            <div className="tile tile-3"></div>
                            <div className="tile tile-4"></div>

                            <div className="tile tile-5"></div>
                            <div className="tile tile-6"></div>
                            <div className="tile tile-7"></div>
                            <div className="tile tile-8"></div>

                            <div className="tile tile-9"></div>
                            <div className="tile tile-10"></div>
                        </div>

                        <div className="cardline cardline-1"></div>
                        <div className="cardline cardline-2"></div>
                        <div className="cardline cardline-3"></div>
                    </div>
                </div>
            </div>
    </>
)

}

export default CardContainer;