import React, { useEffect } from "react";
import "./LoadingPage.css";
const LoadingPage = () => {
    useEffect(() => {
        const timer = setTimeout(() => { }, 2000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div>
            <section id="main" className="d-flex flex-column">
                <div className="loadingWrapper">
                <span className="spin-loader">CODE</span>
                <span className="spin-loader2">TREK</span>
                </div>
            </section>
        </div>
    );
};

export default LoadingPage;
