import React, { useState, useEffect } from 'react';
import { LikeIcon, ThumbIcon } from '../../component/Utils/Svgs';
import { updateCommentRating, checkUserLike } from '../../component/Utils/FirebaseOperations';

const CommentRating = ({ commentId, likeCount }) => {
    const [userLiked, setUserLiked] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        const fetchUserLike = async () => {
            const liked = await checkUserLike(commentId);
            setUserLiked(liked);
        };
        fetchUserLike();
    }, [commentId]);

    const handleLikeClick = async () => {
        if (isUpdating) return;

        setIsUpdating(true);

        try {
            await updateCommentRating(commentId, userLiked);
            setUserLiked(!userLiked);
        } catch (error) {
            console.error('Error updating comment rating:', error);
        } finally {
            setIsUpdating(false);
        }
    };

    return (
        <button className="expand-code" onClick={handleLikeClick}>
            {likeCount}
            {userLiked ? <LikeIcon /> : <ThumbIcon />}
        </button>
    );
};

export default CommentRating;
