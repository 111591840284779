import { getAuth, signInWithEmailAndPassword, EmailAuthProvider, reauthenticateWithCredential, updatePassword, sendPasswordResetEmail } from "firebase/auth";
import { getFirestore, orderBy, collection, query, where, getDocs, doc, getDoc, updateDoc, arrayUnion,startAt, increment, setDoc, deleteDoc, onSnapshot, arrayRemove, addDoc, limit as firestoreLimit, serverTimestamp } from "firebase/firestore";

const db = getFirestore();
const auth = getAuth();
export const user = auth.currentUser;
export const getCurrentUser = () => auth.currentUser;

// USER AUTH

export const loginUser = async (email, password) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
        return true;
    } catch (error) {
        console.error(error.message);
        throw error;
    }
};

export const fetchUserData = async (uid, cachedUserData, setCachedUserData, bypassCache = false) => {
    if (!uid) {
        throw new Error('UID is required');
    }

    if (!bypassCache && cachedUserData[uid]) {
        return cachedUserData[uid];
    }

    try {
        const userDocRef = doc(db, 'users', uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            setCachedUserData(prevCachedUserData => ({ ...prevCachedUserData, [uid]: userData }));
            return userData;
        } else {
            throw new Error('User data not found');
        }
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error; 
    }
};


export const changePassword = async (currentPassword, newPassword) => {
    const user = getCurrentUser();
    try {

        const reauthenticate = () => {
            const credential = EmailAuthProvider.credential(user.email, currentPassword);
            return reauthenticateWithCredential(user, credential);
        };

        await reauthenticate();
        await updatePassword(user, newPassword);
        return true;
    } catch (error) {
        console.error(error.message);
        throw error;
    }
};


// TICKETS CRUD y otras weas



export const fetchTickets = async (tags = [], limit = null) => {
    try {
        const ticketsCollection = collection(db, 'ticket');

        let ticketsQuery;

        if (tags.length > 0) {
            ticketsQuery = query(ticketsCollection,
                where('tags', 'array-contains-any', tags),
                where('estado', '!=', 'cerrado')
            );
        } else {
            ticketsQuery = query(ticketsCollection,
                where('estado', '==', 'abierto')
            );
        }
        ticketsQuery = query(ticketsQuery, orderBy('createdAt', 'desc'));

        if (limit) {
            ticketsQuery = query(ticketsQuery, firestoreLimit(limit));
        }

        const querySnapshot = await getDocs(ticketsQuery);
        return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const fetchTicketById = async (ticketId) => {
    try {
        const ticketRef = doc(db, 'ticket', ticketId);
        const ticketDoc = await getDoc(ticketRef, { source: 'cache' });
        return ticketDoc.exists() ? { id: ticketId, ...ticketDoc.data() } : null;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
export const fetchCommentCount = async (ticketId) => {
    try {
        const commentsCollectionRef = collection(db, 'comment');
        const commentsQuery = query(commentsCollectionRef, where('ticketId', '==', ticketId));
        const commentSnapshot = await getDocs(commentsQuery);

        const commentCount = commentSnapshot.size;

        return commentCount;
    } catch (error) {
        console.error(error);
        throw error;
    }
};



export const fetchUserTickets = async (userId) => {
    try {
        const userTicketsQuery = query(collection(db, 'ticket'), where('uid', '==', userId));
        const querySnapshot = await getDocs(userTicketsQuery);
        return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
        console.error(error);
        throw error;
    }
};




export const createNewTicket = async (newTicketData) => {
    const user = getCurrentUser();
    if (!user) {
        console.error('usuario no registrado');
        return;
    }

    try {
       await addDoc(collection(db, "ticket"), newTicketData);
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const updateTicketState = async (ticketId, newState) => {
    try {
        const ticketDocRef = doc(db, "ticket", ticketId);
        await updateDoc(ticketDocRef, { estado: newState });
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
};


export const addComment = async (commentData, ticketId, commentType) => {
    const user = getCurrentUser();
    if (!user) {
        console.error('usuario no registrado');
        return;
    }

    const newComment = {
        ...commentData,
        createdAt: new Date(),
        uid: user.uid,
    };

    if (commentType === 'main') {
        newComment.ticketId = ticketId;
        try {
            await addDoc(collection(db, 'comment'), newComment);
            return true;
        } catch (error) {
            console.error(error);
            throw error;
        }
    } else {
        try {
            await addDoc(collection(db, 'comment', ticketId, 'replies'), newComment);
            return true;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
};

export const updateCommentRating = async (commentId, userLiked) => {
    const user = getCurrentUser();
    const commentDocRef = doc(db, 'comment', commentId);
    const userLikesDocRef = doc(db, 'users', user.uid, 'likes', commentId);

    if (!userLiked) {
        await updateDoc(commentDocRef, {
            rating: arrayUnion(user.uid),
            likeCount: increment(1),
        });
        await setDoc(userLikesDocRef, { commentId });
    } else {
        await updateDoc(commentDocRef, {
            rating: arrayRemove(user.uid),
            likeCount: increment(-1),
        });
        await deleteDoc(userLikesDocRef);
    }
};
export const updateViewCount = async (ticketId) => {
    try {
        const ticketRef = doc(db, 'ticket', ticketId);

        await updateDoc(ticketRef, {
            viewCount: increment(1),
        });

        return true;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const checkUserLike = async (commentId) => {
    const user = getCurrentUser();
    const userLikesDocRef = doc(db, 'users', user.uid, 'likes', commentId);
    const userLikeDocSnapshot = await getDoc(userLikesDocRef);
    return userLikeDocSnapshot.exists();
};

export const fetchCommentsByTicketId = async (ticketId) => {
    try {
        const commentQuery = query(collection(db, "comment"), where("ticketId", "==", ticketId));
        const commentSnapshot = await getDocs(commentQuery);
        return commentSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
        console.error(error);
        throw error;
    }
};


export const fetchReplies = async (commentId) => {
    try {
        const subcommentsQuery = query(collection(db, 'comment', commentId, 'replies'));
        const subcommentsSnapshot = await getDocs(subcommentsQuery);
        return subcommentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const PasswordResetEmail = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
    } catch (error) {
        console.error(error.message);
        throw error;
    }
};
export const fetchFilteredTickets = async (
    userId = null,
    selectedEstado = 'abierto',
    selectedTags = [],
    sortOrder = 'asc',
    limit = 50,
    selectedType = null,
    startDate = null
) => {
    selectedType = userId ? null : selectedType
    if (sortOrder !== 'asc' && sortOrder !== 'desc') {
        throw new Error('Invalid sortOrder: must be "asc" or "desc"');
    }

    const ticketsCollection = collection(db, 'ticket');

    try {
        let userTicketsQuery = query(ticketsCollection);

        if (userId) {
            userTicketsQuery = query(userTicketsQuery, where('uid', '==', userId));
        }
        if (selectedEstado !== 'all') {
            userTicketsQuery = query(userTicketsQuery, where('estado', '==', selectedEstado));
        }
        if (selectedType) {
            userTicketsQuery = query(userTicketsQuery, where('type', '==', selectedType));
        }

        userTicketsQuery = query(userTicketsQuery, orderBy('createdAt', sortOrder));
        if (startDate) {
            userTicketsQuery = query(userTicketsQuery, where('createdAt', '>=', startDate));
        }

        userTicketsQuery = query(userTicketsQuery, firestoreLimit(limit));

        const querySnapshot = await getDocs(userTicketsQuery);
        let fetchedTickets = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));

        if (Array.isArray(selectedTags) && selectedTags.length > 0) {
            fetchedTickets = fetchedTickets.filter(ticket => 
                ticket.tags && ticket.tags.some(tag => selectedTags.includes(tag))
            );
        }

        return fetchedTickets;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const fetchLanguages = async () => {
    const db = getFirestore();
    const languagesCollection = collection(db, 'tags');
    const querySnapshot = await getDocs(languagesCollection);

    const languagesList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
    }));

    return languagesList;
};


const generateChatId = (userId1, userId2) => {
    return [userId1, userId2].sort().join('_');
};

export const sendMessage = async (senderId, receiverId, content,usersData) => {
    const chatId = generateChatId(senderId, receiverId);
  
    try {
      const messageData = {
        chatId,
        senderId,
        receiverId,
        content,
        timestamp: serverTimestamp(),
      };
  
      await addDoc(collection(db, "messages"), messageData);
  
      await updateAgenda(senderId, receiverId, chatId, true,usersData);
      await updateAgenda(receiverId, senderId, chatId, false,usersData);
  
  
    } catch (error) {
      console.error("Error sending message:", error);
      throw error;
    }
  };

  const updateAgenda = async (userId, otherUserId, chatId, isSender,usersData) => {
    const agendaRef = collection(db, 'agenda');
    const agendaQuery = query(agendaRef, where('chatId', '==', chatId));

    try {
        const agendaSnapshot = await getDocs(agendaQuery);
        if (agendaSnapshot.empty) {
            await addDoc(agendaRef, {
                chatId,
                userIds: [userId, otherUserId],
                unreadCount: isSender ? 0 : 1,
                usersData:usersData
            });
        } else {
            const agendaDoc = agendaSnapshot.docs[0];
            const currentData = agendaDoc.data();

            let unreadCount = currentData.unreadCount || 0;
            if (!isSender) {
                unreadCount += 1;
            }

            await updateDoc(agendaDoc.ref, {
                unreadCount
            });
        }
    } catch (error) {
        console.error("Error updating agenda:", error);
    }
};


export const fetchMessages = (SelectedUserId, userId, callback) => {
    const q = query(
        collection(db, "messages"),
        where('chatId', 'in', [generateChatId(SelectedUserId, userId), generateChatId(userId, SelectedUserId)]),
        orderBy('timestamp', 'asc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
        const messagesData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        callback(messagesData);
    });

    return unsubscribe;
};


export const fetchAgenda = async (userId, callback) => {
    try {
      const q = query(
        collection(db, "agenda"),
        where("userIds", "array-contains", userId)
      );
  
      const querySnapshot = await getDocs(q);
      const agendaData = querySnapshot.docs.map(doc => ({
        chatId: doc.id,
        ...doc.data()
      }));
   
      callback(agendaData);
    } catch (error) {
      console.error("Error fetching agenda:", error);
      callback([]);
    }
  };
  