import React, { useState } from 'react';
import TagSelector from './TagSelector';
import { Badge } from 'react-bootstrap';

const TagContainer = ({ onTagsChange }) => {
  const [selectedTag, setSelectedTag] = useState('');
  const [addedTags, setAddedTags] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('');

  const handleSelect = (tag) => {
    setSelectedTag(tag.toLowerCase());
  };

  const handleAddTag = () => {
    if (selectedTag && !addedTags.includes(selectedTag)) {
      const updatedTags = [...addedTags, selectedTag];
      setAddedTags(updatedTags);
      setSelectedTag('');
      setSelectedLanguage(''); // Reset the selected language here
      if (onTagsChange) {
        onTagsChange(updatedTags);
      }
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    const updatedTags = addedTags.filter((tag) => tag !== tagToRemove);
    setAddedTags(updatedTags);
    if (onTagsChange) {
      onTagsChange(updatedTags);
    }
  };

  return (
    <>
      <div className="tags">
        <TagSelector
          onSelect={handleSelect}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
        />
        <button className='btn-addtag' onClick={handleAddTag}>+</button>
      </div>

      <div className="selected-tags">
        {addedTags.map((tag, index) => (
          <div
            key={index}
            pill
            onClick={() => handleRemoveTag(tag)}
            style={{ cursor: 'pointer', margin: '5px' }}
            className='tag2'
          >
            {tag}
          </div>
        ))}
      </div>
    </>
  );
};
export default TagContainer;
