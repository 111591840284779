import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { fetchTickets, fetchCommentCount } from "../../component/Utils/FirebaseOperations";
import './HomeBlocks.css'
import CardContainer from "./CardContainer";
import PrismCode from "../../component/TextEditor/PrismCode";
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';

const HomeBlock = ({ showFive = false }) => {
    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [commentCount, setCommentCount] = useState({});
    const [selectedTicketId, setSelectedTicketId] = useState(null);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [topFiveTickets, setTopFiveTickets] = useState([]);
    const navigate = useNavigate();

    // Fetch tickets and comment counts
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const fetchedTickets = await fetchTickets([]);
                const counts = {};
                
                // Fetch comment counts for each ticket
                await Promise.all(
                    fetchedTickets.map(async (ticket) => {
                        const count = await fetchCommentCount(ticket.id);
                        counts[ticket.id] = count;
                    })
                );

                setTickets(fetchedTickets);
                setCommentCount(counts);
            } catch (error) {
                console.error("Error fetching tickets:", error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [showFive]);

    // Calculate combined activity score for each ticket
    const calculateActivityScore = (ticket) => {
        const commentScore = commentCount[ticket.id] || 0;
        const viewScore = ticket.viewCount || 0;
        return commentScore + viewScore;
    };

    // Update top five tickets based on combined activity score
    useEffect(() => {
        // Sort tickets based on combined activity score
        const sortedTickets = [...tickets].sort((a, b) => calculateActivityScore(b) - calculateActivityScore(a));

        // Select top five tickets
        const topFive = sortedTickets.slice(0, 5);
        setTopFiveTickets(topFive);
    }, [tickets, commentCount]);

    if (loading) return <div className="wrapper-container">
        <div className="loadingWrapper">
            <span className="spin-loader">CODE</span>
            <span className="spin-loader2">TREK</span>
        </div>
    </div>;

    if (error) return <p>Error loading tickets</p>;

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div>
            <div className="row">
                <div className="col-md-6">
                    <h2 className="homeblocktitle"><span >&lt;</span>Destacados<span >/&gt;</span></h2>
                    <Slider {...settings}>
                        {topFiveTickets.map((ticket, index) => (
                            <div key={index}>
                                <div className="display-container mainmenu" onClick={() => navigate(`/ticket/${ticket.id}`)}>
                                    <CardContainer>
                                        <div className="display-meta " style={{ marginBottom: '1vw' }}>
                                            {'//'} {formatDistanceToNow(new Date(ticket.createdAt?.toDate()), { addSuffix: true, locale: es })}
                                        </div>
                                        <h3>{ticket.title}</h3>
                                        <div dangerouslySetInnerHTML={{ __html: ticket.text }} />
                                        <PrismCode
                                            code={ticket.code || ""}
                                            language={ticket.language || "javascript"}
                                        />
                                        <div className="info-details">
                                        <p>Comentarios: {commentCount[ticket.id] !== undefined ? commentCount[ticket.id] : 'Cargando...'}</p>
                                        <p>Visitas: {ticket?.viewCount ?? 0}</p>
                                        </div>
                                    </CardContainer>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-5 side-cat-container">
                    <h2 className="homeblocktitle"><span >&lt;</span>Recientes<span >/&gt;</span></h2>
                    {tickets.map((ticket, index) => (
                        <div key={index} className="display-container side-cat" onClick={() => navigate(`/ticket/${ticket.id}`)}>
                            <CardContainer>
                                <h3>{ticket.title}</h3>
                                <div dangerouslySetInnerHTML={{ __html: ticket.text }} />
                                <p>Comentarios: {commentCount[ticket.id] !== undefined ? commentCount[ticket.id] : 'Cargando...'}</p>
                                <p>Visitas: {ticket?.viewCount ?? 0}</p>
                            </CardContainer>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default HomeBlock;
