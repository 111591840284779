import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import StarRating from "./StarRating";
import "./UpdateInfoModal.css";
import { CloseIcon } from "../Utils/Svgs";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import TagContainer from "../TextEditor/TagContainer";
const MySwal = withReactContent(Swal);

const UpdateInfoModal = ({
  show,
  handleClose,
  initialProfession = "",
  initialName = "",
  initialRedes = [],
  initialSkills = [],
  onDataUpdated,
  isNew
}) => {
  const [redes, setRedes] = useState([]);
  const [skills, setSkills] = useState([]);
  const [profession, setProfession] = useState("");
  const [username, setUsername] = useState("");

  const [activeTab, setActiveTab] = useState("details");

  useEffect(() => {
    if (
      show &&
      (redes !== initialRedes ||
        skills !== initialSkills ||
        profession !== initialProfession ||
        username !== initialName)
    ) {
      if (
        JSON.stringify(redes) !== JSON.stringify(initialRedes) ||
        JSON.stringify(skills) !== JSON.stringify(initialSkills) ||
        profession !== initialProfession ||
        username !== initialName
      ) {
        setRedes([...initialRedes || []]);
        setSkills([...initialSkills || []]);
        setProfession(initialProfession || "");
        setUsername(initialName || "");
        setActiveTab("details");
      }
    }
  }, [show]);


  const validateFields = () => {

    if (username.trim() === "") {
      return false;
    }
    const areRedesValid = redes.every(
      (red) => red.type.trim() !== "" && red.url.trim() !== ""
    );
    const areSkillsValid = skills.every(
      (skill) =>
        skill.name.trim() !== "" && skill.rating >= 0 && skill.rating <= 5
    );

    return areRedesValid && areSkillsValid;
  };

  const handleUpdate = async () => {
    if (!validateFields()) {
      MySwal.fire({
        title: "Error",
        text: `Rellena todos los campos`,
        icon: "error",
      });
      return;
    }

    const auth = getAuth();
    const db = getFirestore();
    const currentUser = auth.currentUser;

    if (currentUser) {
      const userDoc = doc(db, "users", currentUser.uid);

      const data = {
        username,
        profession,
        redes,
        skills,
      };

      await updateDoc(userDoc, data);
      onDataUpdated();
    }
    isNew = false
    handleClose();
  };



  const handleRemoveRed = (index) => {
    const updatedRedes = redes.filter((_, i) => i !== index);
    setRedes(updatedRedes);
  };

  const handleRemoveSkill = (index) => {
    const updatedSkills = skills.filter((_, i) => i !== index);
    setSkills(updatedSkills);
  };

  const handleAddSkill = () => {
    setSkills([...skills, { name: "", rating: 0 }]);
  };

  const handleAddRed = () => {
    setRedes([...redes, { type: "", url: "" }]);
  };

  return (
    <Modal className="info-modal" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title id="titulo" style={{ fontSize: '2.3rem' }}>{isNew ? 'Bienvenido, ingresa estos datos para continuar':'Actualizar Información'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="tab-navigation">
            <Button onClick={() => setActiveTab("details")}>Detalles</Button>
            <Button onClick={() => setActiveTab("redes")}>Redes</Button>
            <Button onClick={() => setActiveTab("skills")}>Habilidades</Button>
          </div>

          {activeTab === "details" && (
            <div className="details-container">
              <div className="name-container">
                <h2>Nombre</h2>
                <Form.Group>
                  <Form.Control
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="profession-container">
                <h2>Profesión</h2>
                <Form.Group>
                  <Form.Control
                    type="text"
                    value={profession}
                    onChange={(e) => setProfession(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>
          )}


          {activeTab === "redes" && (
            <div className="network-container">
              <h2>Redes</h2>
              {redes.map((red, index) => (
                <div className="row" key={index} style={{ margin: '1vw' }}>
                  <div className="col-md-5 field">
                    <Form.Group>
                      <Form.Label>Nombre</Form.Label>
                      <Form.Control
                        type="text"
                        value={red.type}
                        onChange={(e) => {
                          const updatedRedes = redes.map((red, i) =>
                            i === index ? { ...red, type: e.target.value } : red
                          );
                          setRedes(updatedRedes);
                        }}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6 field">
                    <Form.Group>
                      <Form.Label>URL</Form.Label>
                      <Form.Control
                        type="text"
                        value={red.url}
                        onChange={(e) => {
                          const updatedRedes = redes.map((red, i) =>
                            i === index ? { ...red, url: e.target.value } : red
                          );
                          setRedes(updatedRedes);
                        }}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-1 delete">
                    <div
                      onClick={() => handleRemoveRed(index)}
                    >
                      <CloseIcon />
                    </div>
                  </div>
                </div>
              ))}
              <Button variant="success" onClick={handleAddRed}>
                +
              </Button>
            </div>
          )}

          {activeTab === "skills" && (
            <div className="skills-container">
              <h2>Habilidades</h2>
              {skills.map((skill, index) => (
                <div className="row" key={index}>
                  <div className="col-md-6 field">
                    <Form.Group>
                      <Form.Label>Lenguaje</Form.Label>
                      <Form.Control
                        type="text"
                        value={skill.name}
                        onChange={(e) => {
                          const updatedSkills = [...skills];
                          updatedSkills[index].name = e.target.value;
                          setSkills(updatedSkills);
                        }}

                      />

                    </Form.Group>
                  </div>
                  <div className="col-md-4 skill-star">
                    <StarRating
                      rating={skill.rating}
                      onRatingChange={(rating) => {
                        const updatedSkills = skills.map((skill, i) =>
                          i === index ? { ...skill, rating } : skill
                        );
                        setSkills(updatedSkills);
                      }}
                    />
                  </div>
                  <div className="col-md-1 delete">
                    <div
                      onClick={() => handleRemoveSkill(index)}
                    >
                      <CloseIcon />
                    </div>
                  </div>
                </div>
              ))}
              <Button variant="success" onClick={handleAddSkill}>
                +
              </Button>
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={handleUpdate}>
          Actualizar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateInfoModal;
