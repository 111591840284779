import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import PassChangeModal from "../../component/RegisterModal/PassChangeModal";
import UpdateInfoModal from "../../component/ProfileModals/UpdateInfoModal";
import { PauseIcon, CheckIcon, CloseIcon } from '../../component/Utils/Svgs';
import { StarRating } from '../../component/Utils/Svgs';
import { fetchUserData, fetchUserTickets, updateTicketState, getCurrentUser } from '../../component/Utils/FirebaseOperations';
import './Profile.css';
import AOS from "aos";
import ChatComponent from "../../component/ChatComponent/ChatComponent";
AOS.init();
const MySwal = withReactContent(Swal);

const Profile = ({ onClose,selectedUser = null,handleSelectAgendaUser,isNew }) => {
  const [activeTab, setActiveTab] = useState("info");
  const [showModal, setShowModal] = useState(false);
  const [userTickets, setUserTickets] = useState([]);
  const [cachedUserData, setCachedUserData] = useState({});
  const [userData, setUserData] = useState({
    name: "",
    profession: "",
    email: "",
    redes: [],
    skills: [],
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();
  const [showUpdateInfoModal, setShowUpdateInfoModal] = useState(false);
  const [updatedData, setUpdatedData] = useState({});
  const user = getCurrentUser()
  const fetchUpdatedUserData = async (bypassCache = false) => {
    if (user) {
      const updatedUserData = await fetchUserData(user.uid, cachedUserData, setCachedUserData, bypassCache);
      setUpdatedData(updatedUserData);
    }
  };

  useEffect(() => {
    fetchUpdatedUserData();
  }, [updatedData]);


  const handleTabClick = (target) => {
    setActiveTab(target);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const fetchTickets = async () => {
    if (user) {
      try {
        const tickets = await fetchUserTickets(user.uid);
        setUserTickets(tickets);
      } catch (error) {
        console.error("Error fetching user tickets:", error);
      }
    } else {
      console.error("No authenticated user found");
    }
  };

  const handleUpdateTicketState = async (ticketId, newState) => {
    const success = await updateTicketState(ticketId, newState);
    if (success) {
      MySwal.fire({
        text: `Nuevo estado del ticket: ${newState}`,
        icon: "success",
      });
      const tickets = await fetchUserTickets();
      setUserTickets(tickets);
    } else {
      MySwal.fire({
        title: "Error",
        text: `Error al cambiar el estado`,
        icon: "error",
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        try {
          const userData = await fetchUserData(user.uid, cachedUserData, setCachedUserData);
          setUserData(userData);
          if (!userData.username && !showUpdateInfoModal) {
            setShowUpdateInfoModal(true);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
      setIsLoaded(true);
    };
    fetchData();
  }, [cachedUserData, showUpdateInfoModal]);

  useEffect(() => {
    if (activeTab === "tags") {
      fetchTickets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);


  return (
    <>
      {!isLoaded ? (
        <div className="wrapper-container"> <div className="loadingWrapper"> <span className="spin-loader">CODE</span> <span className="spin-loader2">TREK</span> </div> </div>
      ) : (
        <div className="card row profile-modal">
          <div className="main-frame profile-page">
            <div className="main-box">
              <span className="close-profile" onClick={onClose}>
                <CloseIcon />
              </span>
              <div className="tab-container">
                <div className={`tab ${activeTab === "info" ? "active" : ""}`} onClick={() => handleTabClick("info")}>
                  Información
                </div>
                <div className={`tab ${activeTab === "exp" ? "active" : ""}`} onClick={() => handleTabClick("exp")}>
                  Notificaciones
                </div>
                <div className={`tab ${activeTab === "tags" ? "active" : ""}`} onClick={() => handleTabClick("tags")}>
                  Mis Tickets
                </div>
              </div>

              <div className="profile-box">
                <div className="window-container profile">
                  <p>
                    <span id="usuario-name" style={{ color: 'white' }}> {userData.username || "..."} </span>
                    <span id="usuario-title" style={{ color: 'white' }}> {userData.profession || "..."} </span>
                  </p>
                </div>
                <div className={`profile-item ${activeTab === "info" ? "active" : ""}`} id="info">
                  <div className="row">
                    <div className="details">
                      <div className="row">
                        <div className="col-4 inforow">
                          <p> <span style={{ color: "#00aaff" }}>Nombre:</span> {userData.username} </p>
                          <p> <span style={{ color: "#00aaff" }}>Profesión:</span> {userData.profession} </p>
                          <p> <span style={{ color: "#00aaff" }}>Correo:</span> {userData.email} </p>

                          <h3>Redes:</h3>
                          {(userData.redes || []).map((red, index) => (
                            <p key={index}>
                              <span style={{ color: "#00aaff" }}>{red.type}:</span> <a href={red.url} target="_blank" rel="noopener noreferrer">{red.url}</a>
                            </p>
                          ))}
                        </div>
                        <div className="col-3 skillrow" style={{ maxHeight: "25vw", overflowY: "scroll" }}>
                          {(userData.skills || []).map((skill, index) => (
                            <div className="exp-line" key={index}>
                              <h4>{skill.name}</h4>
                              <StarRating rating={skill.rating} onRatingChange={() => { }} />
                              <hr />
                            </div>
                          ))}
                        </div>
                        <div className="offset-1 col-2">
                          <button id="btn-edit" onClick={() => setShowUpdateInfoModal(true)}>
                            Modificar Información
                          </button>
                          <button id="btn-edit" onClick={() => setShowModal(true)}>
                            Cambiar Contraseña
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`profile-item ${activeTab === "exp" ? "active" : ""}`} id="exp" >
                  <div className="row">
                    <div className="details">
                      <div className="row">
                        <h2 style={{marginLeft:'20px'}}>agenda..</h2>
                        <ChatComponent fromProfile={true}  handleSelectAgendaUser={handleSelectAgendaUser} />

                      </div>
                    </div>
                  </div>
                </div>

                <div className={`profile-item ${activeTab === "tags" ? "active" : ""}`} id="tags">
                  <div className="row">
                    <div className="details">
                      <div className="row">
                        <div className="col-md-8 col-12">
                          <div className="user-tickets">
                            {userTickets.map((ticket) => (
                              <div className="ticket-record" key={ticket.id} onClick={() => navigate(`/ticket/${ticket.id}`)}>
                                <span>
                                  <h4>{ticket.title}</h4>
                                  <p>Comentarios: {ticket.commentCount}</p>
                                  <p>Estado: {ticket.estado}</p>
                                </span>
                                <div className="ticket-options">
                                  <div className="open-ticket" onClick={(e) => { e.stopPropagation(); handleUpdateTicketState(ticket.id, "abierto"); }}>
                                    <CheckIcon />
                                  </div>
                                  <div className="close-ticket" onClick={(e) => { e.stopPropagation(); handleUpdateTicketState(ticket.id, "cerrado"); }}>
                                    <CloseIcon />
                                  </div>
                                  <div className="pause-ticket" onClick={(e) => { e.stopPropagation(); handleUpdateTicketState(ticket.id, "pausa"); }}>
                                    <PauseIcon />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <PassChangeModal show={showModal} handleClose={handleModalClose} />
              <UpdateInfoModal show={showUpdateInfoModal} handleClose={() => setShowUpdateInfoModal(false)} isNew={isNew} initialRedes={userData?.redes} initialSkills={userData?.skills} initialProfession={userData?.profession} initialName={userData?.username} onDataUpdated={() => fetchUpdatedUserData(true)} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
