import React, { useContext, useState, useEffect } from "react";
import "./ChatComponent.css";
import TextEditor from "../TextEditor/TextEditor";
import { sendMessage, getCurrentUser, fetchMessages, fetchAgenda, } from "../../component/Utils/FirebaseOperations";
import { ProfileIcon,ReplyBtn } from "../Utils/Svgs";
import { AuthContext } from "../Auth/AuthContext";

const ChatComponent = ({ selectedUser, fromProfile = null,handleSelectAgendaUser  }) => {
  const { userData } = useContext(AuthContext);

  const [editorContent, setEditorContent] = useState("");
  const [messages, setMessages] = useState([]);
  const [agenda, setAgenda] = useState([]);
  const userId = getCurrentUser()?.uid;

  function getOppositeElement(usersObject, userId) {
    console.log(usersObject)
    const otherElement = usersObject.sender?.uid === userId
      ? usersObject.receiver
      : usersObject.receiver?.uid === userId
        ? usersObject.sender
        : null;
    return otherElement;
  }

  useEffect(() => {
    fetchAgenda(userId, setAgenda);
    if (!userId) return;

    if(selectedUser){
    const unsubscribeMessages = fetchMessages(selectedUser?.uid, userId, setMessages);
    return () => {
      unsubscribeMessages();
    };
    }

  }, [userId]);


  const handleEditorChange = (content) => {
    setEditorContent(content);
  };


  var usersData = {
    sender: userData,
    receiver: selectedUser
  }


  const handleSendMessage = async () => {
    await sendMessage(userId, selectedUser?.uid, editorContent, usersData);
    setEditorContent("");
  };

  return (
    <>
      <div className="row chat-container" style={{height:'inherit'}}>
        <div className="col-4" style={{ margin: "0 auto" }}>
          <div className="user-contact-container">
            {fromProfile && agenda.map((item) => (
              <div key={item.id} className="user-contact-box" onClick={() => { handleSelectAgendaUser(getOppositeElement(item.usersData,userId))}}>
                <ProfileIcon />
                <span className="chat-title" >
                  {getOppositeElement(item.usersData,userId).username?? ''}
                </span>
              </div>
            ))}
          </div>
        </div>

        <div className={fromProfile ? "col-8" : "col-10"} style={{ margin: "0 auto", height: "inherit" }}>
          <div className="chat-box">
            <div className="message-box">
              <div className="chat-content">

                {messages.map((msg) => (
                  <div
                    key={msg.id}
                    className={`msg-item ${msg.senderId === userId ? "msg-sended" : "msg-reply"
                      }`}
                  >
                    <span
                      className="note-body"
                      dangerouslySetInnerHTML={{ __html: msg.content }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          {!fromProfile && (
          <div className="editor">
            <TextEditor
              content={editorContent}
              onEditorChange={handleEditorChange}
            />
          <div className="reply-message-btn" onClick={handleSendMessage}>
            <ReplyBtn/>
          </div>
          </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ChatComponent;
