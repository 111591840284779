import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import CardContainer from "../Home/CardContainer";
import TagContainer from "../../component/TextEditor/TagContainer";
import TextEditor from "../../component/TextEditor/TextEditor";
import CodeEditor from "../../component/TextEditor/CodeEditor";
import { createNewTicket,getCurrentUser } from '../../component/Utils/FirebaseOperations'
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import './TicketEditor.css'

const TicketEditor = () => {
    const [selectedTags, setSelectedTags] = useState([])
    // const [endDateOption, setEndDateOption] = useState('1 Mes');
    const [editorContent, setEditorContent] = useState("");
    const [aceEditorContent, setAceEditorContent] = useState("");
    const [selectedLanguage, setSelectedLanguage] = useState("javascript");
    const [ticketType, setTicketType] = useState('Ticket')
    const [ticketTitle, setTicketTitle] = useState('')
    const user = getCurrentUser()
    const MySwal = withReactContent(Swal);
    const navigate = useNavigate();

    const handleEditorChange = (content) => {
        setEditorContent(content);
    };

    const handleAceEditorChange = (newContent) => {
        setAceEditorContent(newContent);
    };
    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
    };


    const handleTagsChange = (tags) => {
        setSelectedTags(tags);
    };

    // const handleEndDateOptionChange = (event) => {
    //     const option = event.target.value;
    //     setEndDateOption(option)
    // }
    const handleTicketTypeChange = (event) => {
        const option = event.target.value;
        setTicketType(option)
    }

    const handleTicketTitleChange = (event) => {
        const option = event.target.value
        setTicketTitle(option)
    }
    
    const handleSaveClick = async () => {
        if (!ticketTitle || !ticketType || !editorContent || !selectedLanguage || !user.uid) {
          MySwal.fire({
            text: "Rellena todos los campos.",
            icon: "warning",
            confirmButtonText: "Ok",
          });
          return;
        }
      
        // const autoCloseTimestamp = calculateAutoCloseTimestamp(endDateOption);
        const newTicket = {
          title: ticketTitle,
          type: ticketType,
          text: editorContent,
          code: aceEditorContent || '',
          tags: selectedTags || [], 
          language: selectedLanguage,
          createdAt: new Date(),
          uid: user.uid,
          // autoCloseAt: autoCloseTimestamp,
          estado: "abierto"
        };
      
        try {
          const success = await createNewTicket(newTicket);
          if (success) {

            MySwal.fire({
              text: `Ticket creado`,
              icon: "success",
              confirmButtonText: "Entendido",
            }).then(() => {
              navigate("/myTickets");
            });
          }
        } catch (error) {
          console.error("Failed to create ticket:", error);
        }
      };
      

  const calculateAutoCloseTimestamp = (duration) => {
    if (duration === "none") {
      return null;
    } else {
      const now = new Date();
      let autoCloseTimestamp = new Date(now);

      if (duration === "1_day") {
        autoCloseTimestamp.setDate(now.getDate() + 1);
      } else if (duration === "3_days") {
        autoCloseTimestamp.setDate(now.getDate() + 3);
      } else if (duration === "1_week") {
        autoCloseTimestamp.setDate(now.getDate() + 7);
      } else if (duration === "1_month") {
        autoCloseTimestamp.setMonth(now.getMonth() + 1);
      }

      return autoCloseTimestamp;
    }
  };

    return (
        <>
            <div className="post-background board-container">
                <CardContainer>
                    <div className="row ticket-post main-ticket-post">
                        <div className="ticket-title">
                            <label htmlFor="ticket-title-input">Titulo:</label>
                            <input id="ticket-title-input" type="text" value={ticketTitle} onChange={handleTicketTitleChange} />
                            <button className="publish-ticket" onClick={handleSaveClick}>Publicar</button>
                        </div>
                        <div className="col-2 ticket-sidebar">
                            <h2>Tags:</h2>
                            <div className="tag-filters">
                                <TagContainer onTagsChange={handleTagsChange} />
                            </div>
                            {/* <div className="date-filter">
                                <label htmlFor="end-date-option">Cierre:</label>
                                <div className="dropdown">
                                    <button
                                        className="btn btn-secondary dropdown-toggle"
                                        type="button"
                                        id="dropdownMenuButton"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {endDateOption}
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <li> <button className="dropdown-item" value="" onClick={handleEndDateOptionChange}> Hoy </button> </li>
                                        <li> <button className="dropdown-item" value="15 Días" onClick={handleEndDateOptionChange}> 15 días </button> </li>
                                        <li> <button className="dropdown-item" value="1 Mes" onClick={handleEndDateOptionChange}> un mes </button> </li>
                                        <li> <button className="dropdown-item" value="3 Meses" onClick={handleEndDateOptionChange}> 3 meses </button> </li>
                                    </ul>
                                </div>

                            </div> */}
                            <div className="date-filter">
                                <label htmlFor="ticket-type-option">Tipo:</label>
                                <div className="dropdown">
                                    <button
                                        className="btn btn-secondary dropdown-toggle"
                                        type="button"
                                        id="dropdownMenuButton"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {ticketType}
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <li> <button className="dropdown-item" value="Ticket" onClick={handleTicketTypeChange}> Ticket </button> </li>
                                        <li> <button className="dropdown-item" value="Error" onClick={handleTicketTypeChange}> Error </button> </li>
                                        <li> <button className="dropdown-item" value="Consulta" onClick={handleTicketTypeChange}> Consulta </button> </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="row col-10">
                            <div className="col-md-6">
                                <TextEditor content={editorContent} onEditorChange={handleEditorChange} />
                            </div>
                            <div className="col-md-6">

                                <CodeEditor content={aceEditorContent} onEditorChange={handleAceEditorChange} onLanguageChange={handleLanguageChange} />
                            </div>
                        </div>
                    </div>
                </CardContainer>
            </div>
        </>
    )

}

export default TicketEditor;