import React from "react";
import PrismCode from "../../component/TextEditor/PrismCode";
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';
import { EyeIcon, MessageIcon } from "../../component/Utils/Svgs";

const TicketDisplay = ({ ticket, commentCount, viewCount, navigate, isMyTickets, handleTicketStateUpdate }) => (
  <div
    className={`single-clicked-post ${isMyTickets ? 'nope' : ''}`}
    onClick={!isMyTickets ? () => navigate(`/ticket/${ticket.id}`) : undefined}
  >
    <div className="display-container">
      <h3>{ticket.title}</h3>
      <div className="user-tags-container">
        {ticket.tags && ticket.tags.length > 0 ? (
          <div className="tags-list">
            {ticket.tags.map((tag, index) => (
              <span key={index} className="code-tag" style={{ margin: '5px' }}>
                {tag}
              </span>
            ))}
          </div>
        ) : (
          <p>No hay tags disponibles para este ticket.</p>
        )}
      </div> 
      <div dangerouslySetInnerHTML={{ __html: ticket.text }} />
      <PrismCode
        code={ticket.code || ""}
        language={ticket.language || "javascript"}
      />
    </div>
    <div className="display-meta">
      {isMyTickets && (
        <div className="dropdown adm" onClick={(e) => e.stopPropagation()}>
          <button className="dropdown-toggle" type="button" id={`estadoDropdown-${ticket.id}`} data-bs-toggle="dropdown" aria-expanded="false">
            {ticket.estado}
          </button>
          <ul className="dropdown-menu" aria-labelledby={`estadoDropdown-${ticket.id}`}>
            <li><button className="dropdown-item" onClick={() => handleTicketStateUpdate(ticket.id, 'abierto')}>Abierto</button></li>
            <li><button className="dropdown-item" onClick={() => handleTicketStateUpdate(ticket.id, 'pausa')}>En Pausa</button></li>
            <li><button className="dropdown-item" onClick={() => handleTicketStateUpdate(ticket.id, 'cerrado')}>Cerrado</button></li>
          </ul>
        </div>
      )}
      <span><EyeIcon />{viewCount ?? '0'}</span>
      <span><MessageIcon /> {commentCount ?? '0'}</span>
      <p className="display-date">{'//'} {formatDistanceToNow(new Date(ticket.createdAt?.toDate()), { addSuffix: true, locale: es })}</p>
    </div>
    <div className="clickbait-container">
      <span className="clickbait" onClick={() => navigate(`/ticket/${ticket.id}`)}>
        Leer más
      </span>
    </div>
  </div>
);


export default TicketDisplay;
