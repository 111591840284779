import React, { useState } from 'react';
import './PassChangeModal.css';
import { Modal, Button, Form } from 'react-bootstrap';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

const MySwal = withReactContent(Swal);
const auth = getAuth(); // Get the auth instance

const PassRecoverModal = ({ show, handleClose }) => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSendResetEmail = async () => {
        setLoading(true);
        // console.log(email)

        try {
            await sendPasswordResetEmail(auth, email);
            setLoading(false);
            MySwal.fire({
                text: 'Correo enviado, revisa tu bandeja de entrada',
                icon: 'success',
                confirmButtonText: 'Cerrar',
            });
            handleClose();
        } catch (error) {
            setLoading(false);
            MySwal.fire({
                text: 'Error al enviar correo.',
                icon: 'warning',
                confirmButtonText: 'Cerrar',
            });
        }
    };

    return (
        <Modal className="pass-modal" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Recuperar Contraseña</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Correo Electrónico</Form.Label>
                        <Form.Control
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                <Button variant="primary" onClick={handleSendResetEmail} disabled={loading}>
                    {loading ? 'Enviando...' : 'Enviar correo'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PassRecoverModal;
