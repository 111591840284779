import React, { useEffect } from 'react';
import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';

// Import necessary Prism languages
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-scss';
import 'prismjs/components/prism-c';
import 'prismjs/components/prism-cpp';
import 'prismjs/components/prism-csharp';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-ruby';
import 'prismjs/components/prism-go';
import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-rust';
import 'prismjs/components/prism-swift';

import 'prismjs/components/prism-markup-templating';
import 'prismjs/components/prism-php';

const PrismCodeBlock = ({ code, language }) => {

  const langInterpreter = (lang) => {
    let language;

    switch (lang) {
        case 'C#':
            language = 'csharp';
            break;
        case 'Sass':
            language = 'scss';
            break;
        default:
            language = lang;
    }

    // console.log(language);
    return language;
};


  useEffect(() => {
    Prism.highlightAll();
  }, [code, language]);

  return (
    <pre className="line-numbers code-box" style={{ margin: '0' }}>
      <code className={`language-${langInterpreter(language)}`}>
        {code}
      </code>
    </pre>
  );
};

export default PrismCodeBlock;
