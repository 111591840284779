import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../Auth/AuthContext";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase-config";
import { CodeIcon, TicketIcon, ProfileIcon, HomeIcon, TicketsIcon, LogoutIcon, LogoSvg, ExecuteIcon } from "../Utils/Svgs";
import Profile from "../../Pages/Profile/Profile";
import "./Navbar.css";
import { useSpring, animated } from "react-spring";
import { useDrag } from "react-use-gesture";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import SelectedUserProfile from "../../Pages/Profile/SelectedUserProfile";
import CodeEditor from "../CodeRunner/CodeEditor";


const MySwal = withReactContent(Swal);

function Navbar() {
  const { currentUser, userData } = useContext(AuthContext);
  const [showProfile, setShowProfile] = useState(false);
  const [showCodeEditor, setShowCodeEditor] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [toggleExec, setToggleExec] = useState(false)

  const isNew = !userData?.username;

  useEffect(() => {
    if (isNew && currentUser) {
      setShowProfile(true);
    }
  }, [isNew, currentUser]);

  const profilePos = useSpring({ x: 0, y: 0 });
  const bindProfilePos = useDrag((params) => {
    profilePos.x.set(params.offset[0]);
    profilePos.y.set(params.offset[1]);
  });

  const codeEditorPos = useSpring({ x: 0, y: 0 });
  const bindCodeEditorPos = useDrag((params) => {
    codeEditorPos.x.set(params.offset[0]);
    codeEditorPos.y.set(params.offset[1]);
  });

  const fromAgendaPos = useSpring({ x: 0, y: 0 });
  const bindFromAgendaPos = useDrag((params) => {
    fromAgendaPos.x.set(params.offset[0]);
    fromAgendaPos.y.set(params.offset[1]);
  });

  const btnState = !currentUser ? 'disabled' : 'icon-box';

  const handleToggleExec = () => {
    setShowCodeEditor(!showCodeEditor);
  }

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error during logout:", error);
      alert("Error during logout");
    }
  };

  const toggleProfile = () => {
    if (currentUser) {
      if (showProfile) {
        setFadeOut(true);
        setTimeout(() => {
          setShowProfile(false);
          setFadeOut(false);
        }, 500);
      } else {
        setShowProfile(true);
      }
    } else {
      AuthMessage();
    }
  };

  const handleSelectAgendaUser = (selectedUser) => {
    setSelectedUser(selectedUser);
  };

  const AuthMessage = () => {
    MySwal.fire({
      title: "Oops",
      text: `Para continuar, debes iniciar sesión`,
      icon: "error",
    });
  };

  return (
    <nav className="navbar">
      <div className="logo">
        <LogoSvg />
      </div>
      <div className="inner-nav">
        <div className="menu-container">
          <Link to="/" className="icon-box">
            <HomeIcon />
            <p>Home</p>
          </Link>
          <div className={btnState} onClick={toggleProfile}>
            <ProfileIcon />
            <p>Perfil</p>
          </div>
          <div
            className={`profile-container ${showProfile ? 'show' : ''} ${fadeOut ? 'fade-out' : ''}`}
            style={{ position: 'fixed', zIndex: '99999' }}
          >
            {showProfile && (
              <animated.div {...bindProfilePos()} style={{
                x: profilePos.x,
                y: profilePos.y
              }}  >
                <Profile onClose={toggleProfile} isNew={isNew} handleSelectAgendaUser={handleSelectAgendaUser} />
              </animated.div>
            )}
          </div>
          {!currentUser ? (
            <>
              <div onClick={AuthMessage} className={btnState}>
                <TicketIcon />
                <p>Mis Tickets</p>
              </div>
              <div onClick={AuthMessage} className={btnState}>
                <CodeIcon />
                <p>Crear Ticket</p>
              </div>
              <div onClick={AuthMessage} className={btnState}>
                <TicketsIcon />
                <p>Tickets</p>
              </div>
            </>
          ) : (
            <>
              <Link to="/myTickets" className={btnState}>
                <TicketIcon />
                <p>Mis Tickets</p>
              </Link>
              <div className={btnState} onClick={handleToggleExec}>
                <ExecuteIcon />
                <p>Ejecutar</p>
              </div>
              <Link to="/messages" className={btnState}>
                <CodeIcon />
                <p>Crear Ticket</p>
              </Link>
              <Link to="/posts" className={btnState}>
                <TicketsIcon />
                <p>Tickets</p>
              </Link>
            </>
          )}
          {currentUser && (
            <Link to="/" onClick={handleLogout} className="icon-box">
              <LogoutIcon />
              <p>Logout</p>
            </Link>
          )}
        </div>
      </div>
      {showCodeEditor && (
        <animated.div {...bindCodeEditorPos()} style={{
          x: codeEditorPos.x,
          y: codeEditorPos.y,
          position: 'absolute',
          zIndex: '99999',
          background:'linear-gradient(323deg, #101723, #102b42e3)',
          top:'10vw',
          left:'-10vw',
          padding:'1vw',
        }}
          className={'code-editor card'}
        >
          <CodeEditor onCloseEditor={handleToggleExec} />
        </animated.div>
      )}
      {selectedUser && (
        <animated.div {...bindFromAgendaPos()} style={{
          x: fromAgendaPos.x,
          y: fromAgendaPos.y,
          position: 'fixed',
          zIndex: '99999'
        }}
          className={'aersiexplota'}
        >
          <SelectedUserProfile
            onClose={() => setSelectedUser(null)}
            selectedUser={selectedUser}
          />
        </animated.div>
      )}
    </nav>
  );
}

export default Navbar;
