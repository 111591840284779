import React, { useEffect, useState, useContext } from "react";
import "./Home.css";
import Typed from "typed.js";
import Login from "../../component/LoginModal/Login";
import Register from "../../component/RegisterModal/Register";
import '../../component/LoginModal/Login.css';
import { AuthContext } from "../../component/Auth/AuthContext";
import UserControl from "../../component/UserControl/AccountBlock";
import { LogoSvg } from '../../component/Utils/Svgs'
import PostsPage from "../PostsPage/PostsPage";
import HomeBlocks from "./HomeBlocks";
// import BulkImportComponent from "../../component/Utils/BulkLoad";

import AOS from "aos";
AOS.init();
const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenReg, setIsModalOpenReg] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true); 

  const openModalLogin = () => {
    setIsModalOpen(true);
  };

  const closeModalLogin = () => {
    setIsModalOpen(false);
  };

  const openModalRegister = () => {
    setIsModalOpenReg(true)
  }
  const closeModalRegister = () => {
    setIsModalOpenReg(false)
  }

  useEffect(() => {
    if (!currentUser) {
      const options = {
        strings: [
          "React",
          "Ionic",
          "C++",
          "Laravel",
          ".NET",
          "PHP",
          "104 101 108 112",
        ],
        typeSpeed: 50,
        backSpeed: 50,
        loop: true,
      };

      const typedElement = document.querySelector(".typed");
      if (typedElement) {
        const typed = new Typed(typedElement, options);

        setTimeout(() => {
          setIsLoading(false);
        }, 1000);

        return () => {
          typed.destroy();
        };
      }
    } else {
      // If there is a current user, just set loading to false
      setIsLoading(false);
    }
  }, [currentUser]);


  const handleOverlayClick = (event) => {
    if (event.target.classList.contains("modal")) {
      closeModalLogin();
      closeModalRegister();
    }
  };

  return (
    <div>
      <section id="main" className="d-flex flex-column home" style={{ overflow: 'visible',width:'90%' }}>
        {isLoading && (

          <div className="wrapper-container">

            <div className="loadingWrapper">
              <span className="spin-loader">CODE</span>
              <span className="spin-loader2">TREK</span>
            </div>
          </div>
        )}
        <div className='row' style={{ zIndex: '0' }}>
          {!currentUser && (
            <>
              <div className="col-md-5 col-lg-5 col-12 logo-code" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "10vw",position:'relative' }} >
                <LogoSvg />
                <p style={{width:'60%',margin:'0 auto'}}> <span className="typed"></span> </p>
                <UserControl />

              </div>
              <div className={!currentUser ? "col-md-7 col-lg-7 col-12 latest" : 'latest'} >
                <div className="box-featured">
                  <PostsPage showFive={true} loggedIn={currentUser} mainPage={true}/>
                </div>
              </div>
            </>
          )}
          {/* <BulkImportComponent/> */}
          {currentUser && <HomeBlocks showFive={5} />}


        </div>
      </section>

      {isModalOpen && (
        <div className="modal" onClick={handleOverlayClick}>
          <div >
            <span className="close" onClick={closeModalLogin}>
              &times;
            </span>
            <Login openModalRegister={openModalRegister} closeModalLogin={closeModalLogin} />
          </div>
        </div>
      )}

      {isModalOpenReg && (
        <div className="modal" onClick={handleOverlayClick}>
          <div>
            <span className="close" onClick={closeModalRegister}>
              &times;
            </span>
            <Register openModalLogin={openModalLogin} closeModalRegister={closeModalRegister} />
          </div>
        </div>
      )}


    </div>
  );
};

export default Home;


