import React, { useState } from 'react';
import { addComment } from '../../component/Utils/FirebaseOperations';
import TextEditor from '../../component/TextEditor/TextEditor';
import CodeEditor from '../../component/TextEditor/CodeEditor';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

const MySwal = withReactContent(Swal);

const CommentEditor = ({ codeContent, codeLanguage, ticketId, commentType, onCommentAdded }) => {
  const [editorContent, setEditorContent] = useState('');
  const [aceEditorContent, setAceEditorContent] = useState(codeContent || '');


  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const handleAceEditorChange = (newContent) => {
    setAceEditorContent(newContent);
  };

  const handleSaveClick = async () => {
    try {
      if(editorContent === ''){
        console.log(editorContent)
        await addComment(
          {
            text: editorContent,
            code: aceEditorContent,
            language: codeLanguage,
          },
          ticketId,
          commentType
        );
      } else {
        MySwal.fire({
          title: 'Listo',
          text: `Campos vacios, favor revisa`,
          icon: 'error',
          confirmButtonText: 'Entendido',
        });

      }
      MySwal.fire({
        title: 'Listo',
        text: `Comentario ${commentType === 'main' ? 'creado' : 'creando respuesta'}`,
        icon: 'success',
        confirmButtonText: 'Entendido',
      });
      onCommentAdded();
    } catch (error) {
      MySwal.fire({
        title: 'Error',
        text: `Ha ocurrido un error al ${commentType === 'main' ? 'crear' : 'crear respuesta'} el comentario`,
        icon: 'error',
        confirmButtonText: 'Entendido',
      });
    }
  };

  return (
    <div className="container row comment-editor" style={{ position: 'relative' }}>
      <div className="main-frame">
        <span className="comment-line"> </span>
        <div className="main-box">
          <div className="window-container wea">
            <p>
              <span id="usuario-name" style={{ color: 'black' }}>{commentType === 'main' ? 'Añadir Comentario' : 'Responder'}</span>
            </p>
          </div>
          <div className="ticket-box">
            <div className="row">
              <div className="details">
                <div className="row ticket-comment">
                  <div className={commentType === 'main' ? 'col-6' : 'col-12'} id="text-editor">
                    <div className="text-editor-container">
                      <TextEditor content={editorContent} onEditorChange={handleEditorChange} />
                    </div>
                  </div>
                  {commentType === 'main' && 
                  <div className="col-6" id="text-editor">
                    <CodeEditor
                      content={aceEditorContent ? aceEditorContent : codeContent}
                      onEditorChange={handleAceEditorChange}
                      onLanguageChange={codeLanguage}
                    />
                  </div>}
                    <span className="reply-btn comment-button" onClick={handleSaveClick}>
                      {commentType === 'main' ? 'Enviar' : 'Responder'}
                    </span>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentEditor;
