import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate } from 'react-router-dom';
import { updateTicketState, fetchFilteredTickets, fetchCommentCount, getCurrentUser } from "../../component/Utils/FirebaseOperations";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "../MyTickets/MyTickets.css";
import CardContainer from "../Home/CardContainer";
import './PostsPage.css';
import TagContainer from "../../component/TextEditor/TagContainer";
import { startOfDay, subDays, subMonths } from 'date-fns';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import TicketDisplay from "./TicketDisplay";
import { RigthArrowIcon, LeftArrowIcon } from "../../component/Utils/Svgs";

const MySwal = withReactContent(Swal);

const useTicketData = (showFive, userId, selectedEstado) => {
  const [tickets, setTickets] = useState([]);
  const [commentCount, setCommentCount] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedTickets = await fetchFilteredTickets(userId, selectedEstado, [], 'asc', 50);
        setTickets(fetchedTickets);

        const counts = await Promise.all(
          fetchedTickets.map(async (ticket) => {
            const count = await fetchCommentCount(ticket.id);
            return { [ticket.id]: count };
          })
        );
        setCommentCount(Object.assign({}, ...counts));
      } catch (error) {
        console.error("Error fetching tickets:", error);
      }
    };

    fetchData();
  }, [showFive, userId, selectedEstado]);

  return { tickets, commentCount };
};

const PostsPage = ({ showFive = false, mainPage = false, isMyTickets }) => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedType, setSelectedType] = useState('Consulta');
  const [selectedEstado, setSelectedEstado] = useState(isMyTickets ? 'all' : 'abierto');
  const [startDate, setStartDate] = useState('Todo');
  const user = getCurrentUser();
  const userId = isMyTickets ? user?.uid : null;
  const { tickets: mainTickets, commentCount: mainCommentCount } = useTicketData(showFive, userId, selectedEstado);
  const [tickets, setTickets] = useState([]);
  const [commentCount, setCommentCount] = useState({});
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const getStartDate = useCallback((start) => {
    const now = new Date();
    switch (start) {
      case 'Hoy': return startOfDay(now);
      case '15 Dias': return subDays(startOfDay(now), 15);
      case '1 Mes': return subMonths(startOfDay(now), 1);
      case '3 Meses': return subMonths(startOfDay(now), 3);
      case 'Todo':
      default: return subMonths(startOfDay(now), 6);
    }
  }, []);

  const fetchFilterTickets = useCallback(async (userId, tags, type, estado, start) => {
    const startDate = getStartDate(start);

    try {
      const fetchedTickets = await fetchFilteredTickets(
        userId,
        estado,
        tags,
        'asc',
        50,
        type,
        startDate
      );

      setTickets(fetchedTickets);

      const counts = await Promise.all(
        fetchedTickets.map(async (ticket) => {
          const count = await fetchCommentCount(ticket.id);
          return { [ticket.id]: count };
        })
      );
      setCommentCount(Object.assign({}, ...counts));
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  }, [getStartDate]);

  useEffect(() => {
    fetchFilterTickets(userId, selectedTags, selectedType, selectedEstado, startDate);
  }, [fetchFilterTickets, userId, selectedTags, selectedType, selectedEstado, startDate]);

  const handleTagsChange = useCallback((tags) => {
    setSelectedTags(tags);
  }, []);

  const handleTypeChange = useCallback((type) => {
    setSelectedType(type);
  }, []);

  const handleEstadoChange = useCallback((estado) => {
    setSelectedEstado(estado);
  }, []);

  const handleTicketStateUpdate = useCallback(async (ticketId, newEstado) => {
    const result = await MySwal.fire({
      title: 'Cambio de estado:',
      text: `Desea cambiar el estado a ${newEstado}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cerrar'
    });

    if (result.isConfirmed) {
      const success = await updateTicketState(ticketId, newEstado);
      if (success) {
        fetchFilterTickets(userId, selectedTags, selectedType, selectedEstado, startDate);
      }
    }
  }, [fetchFilterTickets, userId, selectedTags, selectedType, selectedEstado, startDate]);

  const handleStartDateChange = useCallback((date) => {
    setStartDate(date);
  }, []);

  const handleNextPage = useCallback(() => {
    setCurrentPage((prevPage) => prevPage + 1);
  }, []);

  const handlePreviousPage = useCallback(() => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  }, []);

  const calculateActivityScore = useCallback((ticket) => {
    const commentScore = (mainPage ? mainCommentCount : commentCount)[ticket.id] || 0;
    const viewScore = ticket.viewCount || 0;
    return commentScore + viewScore;
  }, [mainPage, mainCommentCount, commentCount]);

  const topFiveTickets = useMemo(() => {
    return [...mainTickets]
      .sort((a, b) => calculateActivityScore(b) - calculateActivityScore(a))
      .slice(0, 5);
  }, [mainTickets, calculateActivityScore]);

  const paginatedTickets = useMemo(() => 
    tickets.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage),
    [tickets, currentPage]
  );

  const renderPaginationControls = useCallback(() => (
    <div className="pagination-controls">
      <button onClick={handlePreviousPage} disabled={currentPage === 1}>
        <LeftArrowIcon/>
      </button>
      <span>pagina {currentPage}</span>
      <button onClick={handleNextPage} disabled={currentPage * itemsPerPage >= tickets.length}>
        <RigthArrowIcon/>
      </button>
    </div>
  ), [currentPage, handleNextPage, handlePreviousPage, tickets.length]);

  const renderMainPage = () => (
    <div className={`postpage mytickets mainpage`} style={{ padding: '0', background: 'none',marginTop:'0' }}>
      <div className="col-md-12">
        <div className="ticket-list">
          {topFiveTickets.map(ticket => (
            <TicketDisplay 
              key={ticket.id}
              ticket={ticket} 
              commentCount={mainCommentCount[ticket.id]} 
              viewCount={ticket.viewCount} 
              navigate={navigate} 
            />
          ))}
        </div>
      </div>
    </div>
  );

  const renderFullPage = () => (
    <div className="post-background board-container">
      <CardContainer>
        <div className="ticket-tabs">
          {isMyTickets ? (
            <>
              <span className="ticket-tab-item" onClick={() => handleEstadoChange('abierto')}>Abiertos</span>
              <span className="ticket-tab-item" onClick={() => handleEstadoChange('pausa')}>En Pausa</span>
              <span className="ticket-tab-item" onClick={() => handleEstadoChange('cerrado')}>Cerrados</span>
            </>
          ) : (
            <>
              <span className="ticket-tab-item" onClick={() => handleTypeChange('Consulta')}>Consultas</span>
              <span className="ticket-tab-item" onClick={() => handleTypeChange('Error')}>Errores</span>
              <span className="ticket-tab-item" onClick={() => handleTypeChange('Ticket')}>Tickets</span>
            </>
          )}
        </div>
        <div className="row ticket-post main-ticket-post">
          <div className="col-3 ticket-sidebar">
            <div className="tag-filters">
              <label id="filtrolabel">Tags:</label>
              <TagContainer onTagsChange={handleTagsChange} />
            </div>
            <DateFilter startDate={startDate} handleStartDateChange={handleStartDateChange} />
          </div>
          <div className="col-md-7 col-12 content-body">
            {paginatedTickets.map(ticket => (
              <TicketDisplay 
                key={ticket.id} 
                ticket={ticket} 
                commentCount={commentCount[ticket.id]} 
                viewCount={ticket.viewCount} 
                navigate={navigate} 
                isMyTickets={isMyTickets} 
                handleTicketStateUpdate={handleTicketStateUpdate} 
              />
            ))}
            {renderPaginationControls()}
          </div>
        </div>
      </CardContainer>
    </div>
  );

  return mainPage ? renderMainPage() : renderFullPage();
};

const DateFilter = React.memo(({ startDate, handleStartDateChange }) => (
  <div className="date-filter">
    <label htmlFor="start-date">Antigüedad:</label>
    <div className="dropdown" onClick={(e) => e.stopPropagation()}>
      <button className="dropdown-toggle" type="button" id="startDateDropdown" data-bs-toggle="dropdown" aria-expanded="false">
        {startDate || "Seleccionar fecha"}
      </button>
      <ul className="dropdown-menu" aria-labelledby="startDateDropdown">
        <li><button className="dropdown-item" onClick={() => handleStartDateChange("Hoy")}>Hoy</button></li>
        <li><button className="dropdown-item" onClick={() => handleStartDateChange("15 dias")}>Últimos 15 días</button></li>
        <li><button className="dropdown-item" onClick={() => handleStartDateChange("1 mes")}>Último mes</button></li>
        <li><button className="dropdown-item" onClick={() => handleStartDateChange("3 meses")}>Últimos 3 meses</button></li>
      </ul>
    </div>
  </div>
));

export default PostsPage;