import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { StarRating, CloseIcon } from '../../component/Utils/Svgs';
import { fetchUserTickets } from '../../component/Utils/FirebaseOperations';
import ChatComponent from "../../component/ChatComponent/ChatComponent";
import './Profile.css';
import './SelectedUserProfile.css'

const SelectedUserProfile = ({ onClose, selectedUser }) => {
    const [activeTab, setActiveTab] = useState("info");
    const [userTickets, setUserTickets] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (activeTab === "tags" && selectedUser?.uid) {
            fetchTickets(selectedUser.uid);
        }
    }, [activeTab, selectedUser?.uid]);

    const fetchTickets = async (userId) => {
        try {
            const tickets = await fetchUserTickets(userId);
            setUserTickets(tickets);
        } catch (error) {
            console.error("Error fetching user tickets:", error);
        }
    };

    const tabs = useMemo(() => [
        { id: "info", label: "Información" },
        { id: "exp", label: "Mensajes" },
        { id: "tags", label: "Tickets creados" }
    ], []);

    if (!selectedUser) {
        return <div>Loading user profile...</div>;
    }

    return (
        <div className="row">
            <div className="main-frame profile-page">
                <div className="main-box">
                    <span className="close-profile" onClick={onClose}>
                        <CloseIcon />
                    </span>
                    <div className="tab-container">
                        {tabs.map(tab => (
                            <div
                                key={tab.id}
                                className={`tab ${activeTab === tab.id ? "active" : ""}`}
                                onClick={() => setActiveTab(tab.id)}
                            >
                                {tab.label}
                            </div>
                        ))}
                    </div>

                    <div className="profile-box">
                        <UserInfoHeader username={selectedUser.username} profession={selectedUser.profession} />
                        
                        {activeTab === "info" && <InfoTab user={selectedUser} />}
                        {activeTab === "exp" && <ChatComponent selectedUser={selectedUser} />}
                        {activeTab === "tags" && <TicketsTab tickets={userTickets} navigate={navigate} />}
                    </div>
                </div>
            </div>
        </div>
    );
};

const UserInfoHeader = ({ username, profession }) => (
    <div className="window-container profile">
        <p>
            <span id="usuario-name" style={{ color: 'white' }}>{username || "..."}</span>
            <span id="usuario-title" style={{ color: 'white' }}>{profession || "..."}</span>
        </p>
    </div>
);

const InfoTab = ({ user }) => (
    <div className="profile-item active" id="info">
        <div className="row">
            <div className="details">
                <div className="row">
                    <div className="col-6 inforow">
                        <p><span style={{ color: "#00aaff" }}>Nombre:</span> {user.username}</p>
                        <p><span style={{ color: "#00aaff" }}>Profesión:</span> {user.profession}</p>
                        <p><span style={{ color: "#00aaff" }}>Correo:</span> {user.email}</p>
                        <h3>Redes:</h3>
                        {(user.redes || []).map((red, index) => (
                            <p key={index}>
                                <span style={{ color: "#00aaff" }}>{red.type}:</span> 
                                <a href={red.url} target="_blank" rel="noopener noreferrer">{red.url}</a>
                            </p>
                        ))}
                    </div>
                    <div className="col-6 skillrow" style={{ maxHeight: "25vw", overflowY: "scroll" }}>
                        {(user.skills || []).map((skill, index) => (
                            <div className="exp-line" key={index}>
                                <h4>{skill.name}</h4>
                                <StarRating rating={skill.rating} onRatingChange={() => {}} />
                                <hr />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const TicketsTab = ({ tickets, navigate }) => (
    <div className="profile-item active" id="tags">
        <div className="row">
            <div className="details" style={{ alignItems: 'center' }}>
                <div className="col-md-10 col-12">
                    <div className="user-tickets">
                        {tickets.map((ticket) => (
                            <div className="ticket-record" key={ticket.id} onClick={() => navigate(`/ticket/${ticket.id}`)}>
                                <span>
                                    <h4>{ticket.title}</h4>
                                    <p>Comentarios: {ticket.commentCount}</p>
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default SelectedUserProfile;